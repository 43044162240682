import React, { useState, useEffect } from "react";
import "./App.css";
import {
  Page,
  Card,
  Layout,
  TextField,
  Select,
  Button,
  OptionList,
  Listbox,
} from "@shopify/polaris";
import { TitleBar, useAppBridge, Toast } from "@shopify/app-bridge-react";

import ShopifyAPI from "./Api";

const Configurations = () => {
  const [catText, setCatText] = useState(null);
  const [productCategory, setProductCategory] = useState([]);
  const [productText, setProductText] = useState(null);
  const [products, setProducts] = useState([]);
  const [toast, setToast] = useState(null);
  const [toastError, setToastError] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [integration, setIntegrations] = useState({
    id: null,
    storeUrl: null,
    storeId: null,
    active: null,
    config: {
      includeCategories: [],
      includeCategoriesNames: [],
      lastSynced: null,
      cartOption: "clear",
      kitProducts: [],
      kitProductsNames: [],
      includeTags: false,
      includeDescription: false,
    },
  });
  const app = useAppBridge();

  useEffect(() => {
    ShopifyAPI(app)
      .get(`${process.env.REACT_APP_LIFEDNA_API}/shopify/integrations`)
      .then((res) => {
        setIntegrations(res.data);
      });
  }, [app]);

  const syncStore = () => {
    ShopifyAPI(app)
      .post(`${process.env.REACT_APP_LIFEDNA_API}/plugins/shopify/products/sync/` + integration.id)
      .then((res) => {
        setToast('Successfully synced changes.');
        setToastError(false);
        setShowToast(true);
      })
      .catch((err) => {
        setToast(err.response.data.detail);
        setToastError(true);
        setShowToast(true);
      });
  };

  const getProductCategory = (text) => {
    setCatText(text);
    ShopifyAPI(app)
      .get(`${process.env.REACT_APP_LIFEDNA_API}/shopify/product-collections`, { params: { name: text } })
      .then((res) => {

        console.log(res)

        const categories = res.data;
        const uniqCategories = [];
        const finalCategoriesArray = [];

        for (let i = 0; i < categories.length; i++) {

          const product = categories[i]
          const category = product.node.title;

          if(!uniqCategories.includes(category)){
            uniqCategories.push(category);
            finalCategoriesArray.push(product);
          }


        }

        setProductCategory(finalCategoriesArray);
      });
  };

  const getProducts = (text) => {
    setProductText(text);
    ShopifyAPI(app)
      .get(`${process.env.REACT_APP_LIFEDNA_API}/shopify/products`, { params: { name: text } })
      .then((res) => {
        setProducts(res.data);
      });
  };

  const saveChanges = () => {
    ShopifyAPI(app)
      .put(`${process.env.REACT_APP_LIFEDNA_API}/partners/integrations/` + integration.id, integration)
      .then((res) => {
        setToast("Successfully saved changes.");
        setToastError(false);
        setShowToast(true);
      })
      .catch((err) => {
        setToast(err.response.data.detail);
        setToastError(true);
        setShowToast(true);
      });
  };

  const convertStringBoolean = (word) => {
    if (word === "true") {
      return true;
    } else if (word === "false") {
      return false;
    } else {
      return word;
    }
  };

  const handleChange = (e) => {
    let newState = { ...integration };

    newState["active"] = convertStringBoolean(e);

    setIntegrations(newState);
  };

  const handleConfigChange = (value, id) => {
    let config = { ...integration.config };

    config[id] = convertStringBoolean(value);

    let state = { ...integration, config };

    setIntegrations(state);
  };

  const handleAddCategory = (value) => {
    if (integration.config.includeCategories.indexOf(value[0]) !== -1) {
      setToast("Category " + value + " already added.");
      setToastError(true);
      setShowToast(true);
    } else {
      let newIntegration = { ...integration };

      const newCategories = integration.config.includeCategories;
      newCategories.push(value[0]);

      newIntegration.config.includeCategories = newCategories;

      setIntegrations(newIntegration);
    }
  };

  const handleRemoveCategory = (value) => {
    let newIntegration = { ...integration };

    let newCategories = integration.config.includeCategories;
    const removeIndex = newCategories.indexOf(value);
    newCategories.splice(removeIndex, 1);

    newIntegration.config.includeCategories = newCategories;

    setIntegrations(newIntegration);
  };

  const handleAddProduct = (value) => {
    value = value[0]
    let id = value.split(":")[0]
    let name = value.split(":")[1]
    if (integration.config.kitProducts.indexOf(id) !== -1) {
      setToast("Kit Product " + name + " already added.");
      setToastError(true);
      setShowToast(true);
    } else {
      let newIntegration = { ...integration };

      const newProducts = integration.config.kitProducts;
      newProducts.push(id);

      newIntegration.config.kitProducts = newProducts;

      const newProductsNames = integration.config.kitProductsNames;
      newProductsNames.push( {"id": id, "title": name});

      newIntegration.config.kitProductsNames = newProductsNames;

      setIntegrations(newIntegration);
    }
  };

  const handleAddCollection = (value) => {
    value = value[0]
    let id = value.split(":")[0]
    let name = value.split(":")[1]
    if (integration.config.includeCategories.indexOf(id) !== -1) {
      setToast("Collection " + name + " already added.");
      setToastError(true);
      setShowToast(true);
    } else {
      let newIntegration = { ...integration };

      const newCategory = integration.config.includeCategories;
      newCategory.push(id);

      newIntegration.config.includeCategories = newCategory;

      const newCategoryNames = integration.config.includeCategoriesNames;
      newCategoryNames.push( {"id": id, "title": name});

      newIntegration.config.includeCategoriesNames = newCategoryNames;

      setIntegrations(newIntegration);
    }
  }

  const handleRemoveCollection = (value) => {

    let newIntegration = { ...integration };

    let newProducts = integration.config.includeCategories;
    let newProductsNames = integration.config.includeCategoriesNames
    const removeIndex = newProducts.indexOf(value.toString());

    if(removeIndex > -1){
      newProducts.splice(removeIndex, 1);
      newIntegration.config.includeCategories = newProducts;
      newIntegration.config.includeCategoriesNames = newProductsNames.filter( kitProduct =>  kitProduct.id != value  );
    }

    setIntegrations(newIntegration);
  }

  const handleRemoveProduct = (value) => {

    let newIntegration = { ...integration };

    let newProducts = integration.config.kitProducts;
    let newProductsNames = integration.config.kitProductsNames
    const removeIndex = newProducts.indexOf(value.toString());

    if(removeIndex > -1){
      newProducts.splice(removeIndex, 1);
      newIntegration.config.kitProducts = newProducts;
      newIntegration.config.kitProductsNames = newProductsNames.filter( kitProduct =>  kitProduct.id != value  );
    }

    //console.log('here')

    setIntegrations(newIntegration);
  };

  return (
    <>
      <TitleBar title="Configuration" />
      <Page>
        <Card title="Your LifeDNA Latest Sync Details">
          <Card.Section>
            <Layout>
              <Layout.Section>
                <TextField
                  label="Latest Sync Date"
                  disabled
                  value={new Date(integration.config.lastSynced).toString()}
                />
              </Layout.Section>
              <Layout.Section oneThird>
                <Button primary onClick={syncStore}>
                  Sync Store
                </Button>
              </Layout.Section>
            </Layout>
          </Card.Section>
        </Card>
        <Card title="Your LifeDNA Integration Details">
          <Card.Section>
            <Layout>
              <Layout.Section oneHalf>
                <TextField
                  label="Store URL"
                  value={integration.storeUrl}
                  //onChange={handleChange}
                  disabled
                  autoComplete="off"
                />
              </Layout.Section>
              <Layout.Section oneHalf>
                <TextField
                  label="LifeDNA Partner Integration ID"
                  value={integration.id}
                  //onChange={handleChange}
                  disabled
                  autoComplete="off"
                />
              </Layout.Section>
            </Layout>
          </Card.Section>
        </Card>
        <Card title="Your LifeDNA Integration Status">
          <Card.Section>
            <Select
              label="Integration Status"
              options={[
                { value: true, label: "Active" },
                { value: false, label: "Disabled" },
              ]}
              onChange={handleChange}
              value={integration.active}
            />
          </Card.Section>
        </Card>
        <Card title="Product Collections">
          <Card.Section>

            <TextField
              label="Search"
              value={catText}
              onChange={(e) => getProductCategory(e)}
            />
            <OptionList
              title="Collections Result"
              onChange={handleAddCollection}
              options={productCategory.map((product) => ({
                value: `${product.node.id.split("/")[4]}:${product.node.title}`,
                label: product.node.title,
              }))}
              selected={[]}
              role="listbox"
            />
          </Card.Section>
          <Card.Section>
            <Listbox onSelect={handleRemoveCollection}>
              {integration.config.includeCategoriesNames.map((category) => (
                  <Listbox.Option key={category.id} value={category.id}>{category.title}</Listbox.Option>
              ))}
            </Listbox>
          </Card.Section>
        </Card>
        <Card title="Kit Products">
          <Card.Section>
            <TextField
              label="Products"
              value={productText}
              onChange={(e) => getProducts(e)}
            />
            <OptionList
              title="Product Result"
              onChange={handleAddProduct}
              options={products.map((product) => ({
                value: `${product.node.id.split("/")[4]}:${product.node.title}`,
                label: product.node.title,
              }))}
              selected={[]}
            />
          </Card.Section>
          <Card.Section>
            <Listbox onSelect={handleRemoveProduct}>
              {integration.config.kitProductsNames.map((kitProduct) => (
                <Listbox.Option key={kitProduct.id} value={kitProduct.id}>{kitProduct.title}</Listbox.Option>
              ))}
            </Listbox>
          </Card.Section>
        </Card>
        <Card title="Your LifeDNA Customizations">
          <Card.Section>
            <Layout>
              <Layout.Section>
                <Select
                  id="includeTags"
                  label="Include Tags"
                  options={[
                    { value: true, label: "Yes" },
                    { value: false, label: "No" },
                  ]}
                  onChange={handleConfigChange}
                  value={integration.config.includeTags}
                />
              </Layout.Section>
              <Layout.Section>
                <Select
                  id="includeDescription"
                  label="Include Description"
                  options={[
                    { value: true, label: "Yes" },
                    { value: false, label: "No" },
                  ]}
                  onChange={handleConfigChange}
                  value={integration.config.includeDescription}
                />
              </Layout.Section>
              <Layout.Section>
                <Select
                  id="cartOption"
                  label="Cart Behavior"
                  options={[
                    { value: "clear", label: "Clear" },
                    { value: "checkout", label: "Checkout" },
                    { value: "keep", label: "Keep" },
                  ]}
                  onChange={handleConfigChange}
                  value={integration.config.cartOption}
                />
              </Layout.Section>
            </Layout>
          </Card.Section>
        </Card>
      </Page>
      <Page>
        <Button primary onClick={saveChanges}>
          Save Changes
        </Button>
      </Page>
      {showToast ? (
        <Toast
          content={toast}
          error={toastError}
          onDismiss={() => setShowToast(false)}
        />
      ) : null}
    </>
  );
};

export default Configurations;
